import React from 'react';
import { Link } from 'react-router-dom';
import "./MainNavbar.scss";

// Components
import NavbarTop from 'Components/NavbarTop/NavbarTop';

function MainNavbar() {

    const dataLink = [
        { id : 1 , title : "หน้าแรก" , linkTo : "/"},{ id : 2 , title : "สมัครสมาชิก" , linkTo : "/register"},{ id : 3 , title : "บทความ" , linkTo : "/blogs"},
        { id : 4 , title : "แทงบอลออนไลน์" , linkTo : "/article/betsoccer"},{ id : 6 , title : "แทงหวยออนไลน์" , linkTo : "/article/lotto" },{ id : 7 , title : "โปรโมชั่น" , linkTo : "/promotion"}
    ];

  return (
    <div className='main-navbar-container'>
        <NavbarTop />
        <div className='main-navbar-wrap'>
            <ul>
                {dataLink.map((item , index) => {
                    return (
                        <li key={index}>
                            <Link to={item.linkTo}>{item.title}</Link>
                        </li>
                    )
                })}
                <li><a href='https://line.me/ti/p/@jimyai'>ติดต่อเรา</a></li>
            </ul>
        </div>
    </div>
  );
};

export default MainNavbar;