import React from 'react';
import { Link } from 'react-router-dom';
import "./Navbarbottom.scss";

// Icons
import { AiOutlineHome } from 'react-icons/ai';
import { BiLogIn } from 'react-icons/bi';
import { FaUserPlus , FaLine } from 'react-icons/fa';
import { TbSpeakerphone } from 'react-icons/tb';

function Navbarbottom() {
  return (
    <div className='navbar_bottom_container'>
        <div className='navbar_bottom_box'>
            <Link to='/'>
                <span><AiOutlineHome /></span>
                <p>หน้าหลัก</p>
            </Link>
            <Link to='/play'>
                <span><BiLogIn /></span>
                <p>เข้าสู่ระบบ</p>
            </Link>
            <Link to='/register'>
                <span><FaUserPlus /></span>
                <p>สมัครสมาชิก</p>
            </Link>
            <Link to="/promotion">
                <span><TbSpeakerphone /></span>
                <p>โปรโมชั่น</p>
            </Link>
        </div>
        {/* <Totop /> */}
    </div>
  )
}

export default Navbarbottom