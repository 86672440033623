import { Suspense , lazy , useState } from 'react';
import { Routes , Route } from 'react-router-dom';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import './App.css';

// Icons
import { BsLine } from 'react-icons/bs';

// Components
import MainNavbar from 'Components/MainNavbar/MainNavbar';
import Footer from 'Components/Footer/Footer';
import Navbarbottom from 'Components/Navbarbottom/Navbarbottom';

// Pages
const HomePage = lazy(() => import("Pages/Home/Home"));
const ArticlePage01 = lazy(() => import("Pages/Article/Article01/Article01"));
const ArticlePage02 = lazy(() => import("Pages/Article/Article02/Article02"));
const ArticlePage03 = lazy(() => import("Pages/Article/Article03/Article03"));
const ArticlePage04 = lazy(() => import("Pages/Article/Article04/Article04"));
const ArticlePage05 = lazy(() => import("Pages/Article/Article05/Article05"));
const PromotionPage = lazy(() => import("Pages/Promotion/Promotion"));
const BlogsPage = lazy(() => import("Pages/Blogs/Blogs"));
const BlogPage = lazy(() => import("Pages/Blog/Blog"));

const NotFoundPage = lazy(() => import("Pages/Notfound/Notfound"));

const FrameLoginPage = lazy(() => import("Pages/Frame/LoginFrame/FrameLoginPage"));
const FrameRegisterPage = lazy(() => import("Pages/Frame/RegisterFrame/FrameRegisterPage"));

function App() {
  const [ showPage , setShowPage ] = useState(false);
  return (
    // <>
    // { showPage ?
    // <Suspense fallback="Loading...">
    //   <div className='App'>
    //       <>
    //         <MessengerCustomerChat
    //           pageId="110651291864389"
    //           appId="750025749929835"
    //         />
    //       </>
    //       <MainNavbar />
    //       <Routes>
    //         <Route path='/' element={<HomePage />} />
    //         <Route path='/article/jimyai' element={<ArticlePage01 />} />
    //         <Route path='/article/slot' element={<ArticlePage02 />} />
    //         <Route path='/article/casino' element={<ArticlePage03 />} />
    //         <Route path='/article/betsoccer' element={<ArticlePage04 />} />
    //         <Route path='/article/lotto' element={<ArticlePage05 />} />
    //         <Route path='/promotion' element={<PromotionPage />} />
    //       </Routes>
    //       <Footer />
    //       <div className='footer_games_box'>
    //         <img src={gameProvider_pic} alt="provider-games" loading='lazy' />
    //       </div>
    //       <footer className='footer_bottom'>Copyright 2023 © Jimyai.com All Rights Reserved.</footer>
    //       <Navbarbottom />
    //   </div>
    // </Suspense>
    //      : 
    //      <Suspense fallback="Loading...">
    //       <Routes>
    //         <Route path="/" element={<NotFoundPage setShowPage={setShowPage} />} />
    //       </Routes>
    //      </Suspense>
    //   }
    // </>

    <Suspense fallback="Loading...">
      <div className='App'>
        <a href='https://line.me/ti/p/@jimyai' className='line-stick-mobile' ><BsLine /></a>
        <>
          <MessengerCustomerChat
            pageId="110651291864389"
            appId="750025749929835"
          />
        </>
        {/* <MainNavbar /> */}
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/article/jimyai' element={<ArticlePage01 />} />
          <Route path='/article/slot' element={<ArticlePage02 />} />
          <Route path='/article/casino' element={<ArticlePage03 />} />
          <Route path='/article/betsoccer' element={<ArticlePage04 />} />
          <Route path='/article/lotto' element={<ArticlePage05 />} />
          <Route path='/promotion' element={<PromotionPage />} />
          <Route path='/blogs' element={<BlogsPage />} />
          <Route path='/blog/:id' element={<BlogPage />} />

          <Route path='/play' element={<FrameLoginPage />} />
          <Route path='/register' element={<FrameRegisterPage />} />
        </Routes>
        {/* <Footer /> */}
        {/* <Navbarbottom /> */}
      </div>
    </Suspense>
  );
}

export default App;
