import React from 'react';
import { Link } from 'react-router-dom';
import "./Footer.scss";

// Images
import brandLogo from 'Assests/Images/etc/Jimyai.jpg';
import gameProvider_pic from 'Assests/Images/etc/logogame.png';

function Footer() {
  return (
    <footer className='footer-container'>
        <div className='footer_games_box'>
            <img src={gameProvider_pic} alt="provider-games" loading='lazy' />
        </div>
        <div className='footer-logo-wrap'>
            <div className='footer-logo-box'>
                <Link to="/">
                    <img src={brandLogo} alt="Logo-pic" loading='lazy' />
                </Link>
            </div>
            <div className='footer-text-box'>
                <div>
                    <strong>JIMYAI.com เว็บเดิมพันออนไลน์ลิขสิทธิ์แท้ เปิดให้บริการ 24 ชม. ฝากถอนรวดเร็วผ่านระบบอัตโนมัติภายใน 30 วินาที มีเกมให้เลือกเล่นหลากหลายจากค่ายดังกว่า 100 ค่าย มีทั้ง สล็อตออนไลน์ บาคาร่าออนไลน์ คาสิโนสด แทงบอลออนไลน์ แทงหวยออนไลน์ กีฬาทุกประเภทและการเดิมพันอื่นๆอีกมากมาย การันตีด้วยผู้ใช้งานกว่าหลักแสนยูสเซอร์ มีทีมงานพร้อมดูแลตลอดเวลาผ่านไลน์ออฟฟิเชียล 
                    <a href="/" target="_blank" rel="noreferrer" style={{ color : "var(--lineColor)" , fontWeight : "bold" , textDecoration : "none" }}>@JIMYAI</a>
                    </strong>
                </div>
            </div>
        </div>
        <footer className='footer_bottom'>Copyright 2023 © Jimyai.com All Rights Reserved.</footer>
    </footer>
  );
};

export default Footer;