import "./NavbarTop.scss"; // CSS

import React , { useState } from 'react';
import { Link } from 'react-router-dom';


// Images
import brandLogo from 'Assests/Images/etc/Jimyai.jpg';

// Icons
import { FaLine , FaUserPlus , FaBars } from 'react-icons/fa';
import { BsCash } from 'react-icons/bs';
import { MdOutlineClose } from 'react-icons/md';

// Components
import Submenu from 'Components/Submenu/Submenu';

function NavbarTop() {

    const [ showMenu , setShowMenu ] = useState(false);

  return (
    <section className='navbar-top-container'>
        <Submenu showMenu={showMenu} />
        <div className='navbar-top-wrap'>
            <div className='navbar-top-logo-box'>
                <Link to="/">
                    <img src={brandLogo} alt="Logo_Pic" loading='lazy' /> 
                </Link>
            </div>
            <div className='navbar-top-input-wrap'>
                <div className='navbar-top-input-box'>
                    <input type="text" placeholder='Username' />
                    <input type="password" placeholder='Password' />
                    <Link to='/play'>เข้าสู่ระบบ</Link>
                </div>
                <div className='navbar-top-link-box'>
                    <a href='https://line.me/ti/p/@jimyai' style={{ background : "var(--lineColor)" }} ><span><FaLine /></span> @Jimyai</a>
                    <Link to='/register' style={{ background : "#D1A63C" }}><span><FaUserPlus /></span> สมัครสมาชิก</Link>
                    <a href='https://line.me/ti/p/@jimyai' style={{ background : "var(--errorColor)" }}><span><BsCash /></span> ฝาก-ถอน</a>
                </div>
            </div>
            <span className='navbar-top-submenu-icon' onClick={() => setShowMenu(!showMenu)} >{!showMenu ? <FaBars /> : <MdOutlineClose /> }</span>
        </div>
    </section>
  );
};

export default NavbarTop;